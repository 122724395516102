<template>
  <div>
    <base-section
      id="portal"
      class="lightgrey"
      space="0"
    >
      <div class="col s12">
        
        <v-data-table
          :headers="headers"
          :items="companies"
          :sort-by="startSortBy"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar
              flat
              height="extended"
            >
              <v-toolbar-title style="width:100px">Lenders</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-combobox
                      v-model="loantypefilter"
                      :items="loantypes"
                      label="Loan Types"
                      item-text="name"
                      item-value="name"
                      clearable
                      dense
                      filled
                      multiple
                      outlined
                      small-chips
                      solo
                      v-on:change="fetchCompanies"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="6">
                    <v-combobox
                      v-model="propertytypefilter"
                      :items="propertytypes"
                      label="Property Types"
                      item-text="name"
                      item-value="name"
                      clearable
                      dense
                      filled
                      multiple
                      outlined
                      small-chips
                      solo
                      v-on:change="fetchCompanies"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search Lenders"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="loanamount"
                      type="number"
                      label="Loan Amount"
                      v-on:input="fetchCompanies"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-toolbar>
          </template>
          <template v-slot:item.id="{ item }">
            <a :href="'/portal/companies/' + item.id">{{ item.id }}</a>
          </template>
          <template v-slot:item.loanamountrange="{ item }">
            {{ item.loanrangelow | toCurrency }} - {{ item.loanrangehigh | toCurrency }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <!-- <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon> -->
          </template>
          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </base-section>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  // import { Users } from '@/database/users'
  import { Companies } from '@/database/companies'
  import { System } from '@/database/system'
  import { FirebaseResults } from '@/database'

  export default {
    name: 'SectionLenders',
    data: () => ({
      deals: [],
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        id: '',
        companyName: '',
        companyType: '',
        phone: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          zip: '',
        },
      },
      defaultItem: {
        id: '',
        companyName: '',
        companyType: '',
        phone: '',
        address: {
          line1: '',
          line2: '',
          city: '',
          state: '',
          zip: '',
        },
      },
      search: '',
      companytype: 'lender',
      users: [],
      filteredCompanies: [],
      companies: [],
      loanofficers: [],
      propertytypes: [],
      loantypes: [],
      active: true,
      propertytypefilter: '',
      loantypefilter: '',
      loanamount: 0,
    }),
    provide: {
      heading: { align: 'center' },
    },
    computed: {
      // map `this.user` to `this.$store.getters.user`
      ...mapGetters({
        user: 'user',
      }),
      isNewForm () {
        return this.editedIndex === -1
      },
      formTitle () {
        return this.isNewForm ? 'New Item' : 'Edit Item'
      },
      startSortBy () {
        return 'companyName'
      },
      headers () {
        var header = [
          { text: 'Company Name', value: 'companyName' },
          { text: 'Account Exec', value: 'accountExec' },
          { text: 'Phone', value: 'phone' },
          { text: 'Email', value: 'email' },
          { text: 'Loan Amt Range', value: 'loanamountrange' },
          { text: 'Loan Types', value: 'loantypelist' },
          { text: 'Property Types', value: 'propertytypelist' },
          // { text: 'Actions', value: 'actions', sortable: false },
        ]
        
        return header
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      // dialogDelete (val) {
      //   val || this.closeDelete()
      // },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
        this.fetchCompanies(this.active)
        
        System.findOne('propertytypes').then(doc => {
          this.propertytypes = doc.data().data
        })
        System.findOne('loantypes').then(doc => {
          this.loantypes = doc.data().data
        })
      },
      runtest () {
        if (this.usertype === 'borrower'){
          this.usertype = 'loan officer'
        } else {
          this.usertype = 'borrower'
        }
      },
      formatNumber(val) {
        return val.toLocaleString(
          undefined, 
          { minimumFractionDigits: 2 },
        )
      },
      companyHasFilteredLoanType (companyLoanTypes, filterLoanTypes) {
        var returnVal = false
        companyLoanTypes.forEach(lt => {
          filterLoanTypes.forEach(flt => {
            if (lt.name === flt.name){
              returnVal = true
            }
          })
        })
        return returnVal
      },
      companyHasFilteredPropertyType (companyPropertyTypes, filterPropertyTypes) {
        var returnVal = false
        companyPropertyTypes.forEach(lt => {
          filterPropertyTypes.forEach(flt => {
            if (lt.name === flt.name){
              returnVal = true
            }
          })
        })
        return returnVal
      },
      companySupportsLoanAmount (loanrangelow, loanrangehigh, loanamount) {
        loanrangelow = Number(loanrangelow)
        loanrangehigh = Number(loanrangehigh)
        loanamount = Number(loanamount)
        return loanrangelow <= loanamount && loanrangehigh >= loanamount
      },
      fetchCompanies (companyId, active) {
        console.log('fetching companies')
        Companies.findAllLenders().onSnapshot(docs => {
          const results = FirebaseResults.map(docs)
          this.companies = results.map(function(item){
            return item
          })
          // Now check and filter
          if (this.loantypefilter && this.loantypefilter.length > 0) {
            this.companies = this.companies.filter(item => {
              return this.companyHasFilteredLoanType(item.loantypes, this.loantypefilter)
            })
          }
          if (this.propertytypefilter && this.propertytypefilter.length > 0) {
            this.companies = this.companies.filter(item => {
              return this.companyHasFilteredPropertyType(item.propertytypes, this.propertytypefilter)
            })
          }
          if (this.loanamount && !isNaN(this.loanamount) && this.loanamount.length > 0) {
            var nLoanAmount = Number(this.loanamount)
            this.companies = this.companies.filter(item => {
              return this.companySupportsLoanAmount(item.loanrangelow, item.loanrangehigh, nLoanAmount)
            })
          }
          // Create the LoanTypes and PropertyTypes List
          this.companies.forEach(c => {
            c.loantypelist = c.loantypes.map(function(lt){ return lt.name }).join(',\n')
            c.propertytypelist = c.propertytypes.map(function(pt){ return pt.name }).join(',\n')
          })
          
          // console.log(this.companies)
        })
      },
      editItem (item) {
        this.editedIndex = this.users.indexOf(item)
        if (item.address1 && !item.address){
          item.address = { line1: item.address1, line2: item.address2, city: item.addresscity, state: item.addressstate, zip: item.addresszip }
        }
        if (!item.address){
          item.address = { line1: '', line2: '', city: '', state: '', zip: '' }
        }
        
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      // deleteItem (item) {
      //   this.editedIndex = this.desserts.indexOf(item)
      //   this.editedItem = Object.assign({}, item)
      //   this.dialogDelete = true
      // },

      // deleteItemConfirm () {
      //   this.desserts.splice(this.editedIndex, 1)
      //   this.closeDelete()
      // },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      // closeDelete () {
      //   this.dialogDelete = false
      //   this.$nextTick(() => {
      //     this.editedItem = Object.assign({}, this.defaultItem)
      //     this.editedIndex = -1
      //   })
      // },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.companies[this.editedIndex], this.editedItem)
        } else {
          this.companies.push(this.editedItem)
        }
        this.close()
      },

      getCurrentStatus (statuses) {
        return statuses.slice().sort((a, b) => (a.StatusDate < b.StatusDate) ? 1 : -1)[0].StatusName
      },
      filterCompanies (searchTerm) {
        if (searchTerm.length < 2) {
          this.filteredCompanies = [...this.companies]
        } else {
          this.filteredCompanies = filterResults(searchTerm, this.companies, 'companyName').search()
        }
      },
    },
  }
</script>
